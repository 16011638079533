import {combineReducers} from 'redux';

import accounts from 'features/accounts/reducer';
import auth from 'features/auth/reducer';
import billing from 'features/billing/reducer';
import dashboard from 'features/dashboard/reducer';
import editorials, {workingSets} from 'features/editorials/reducer';
import feedback from 'features/feedback/reducer';
import form from 'features/form/reducer';
import issues from 'features/issues/reducer';
import listings from 'features/listings/reducer';
import modal from 'features/modal/slice';
import history from 'features/app/slice';
import notification from 'features/notification/slice';
import outpatient from 'features/outpatient/reducer';
import permissions from 'features/permissions/reducer';
import promoted from 'features/promoted/reducer';
import reviews from 'features/reviews/reducer';
import roles from 'features/roles/reducer';
import standard from 'features/standard/slice';
import transfer from 'features/transfer/reducer';
import twofa from 'features/twofa/reducer';
import users from 'features/users/reducer';
import vip from 'features/vip/reducer';
import vipFlat from 'features/vip/vipFlatSlice';

const resetOnLogout = (reducer) => (state, action) => {
    if (action.type === 'LOGOUT') {
        return reducer(undefined, {type: '@@RESET'});
    }

    return reducer(state, action);
};

const enhanceReducers = (reducers) => {
    return Object.keys(reducers).reduce((acc, key) => {
        acc[key] = resetOnLogout(reducers[key]);
        return acc;
    }, {});
};

const reducers = enhanceReducers({
    users,
    billing,
    auth,
    roles,
    permissions,
    accounts,
    notification,
    form,
    modal,
    history,
    listings,
    dashboard,
    reviews,
    editorials,
    workingSets,
    feedback,
    transfer,
    vip,
    outpatient,
    issues,
    standard,
    promoted,
    twofa,
    vipFlat,
});

// const createRootReducer = (history) =>
//     combineReducers({
//         // router: connectRouter(history),
//         ...reducers,
//     });
export default reducers;

// export default combineReducers(reducers);
