const initialState = {
    isFetching: false,
    types: ['internal', 'external', 'generic'],
    currentRoleId: null,
    entities: {},
};

export default function roles(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_ROLES':
        case 'REQUEST_ROLE':
        case 'REQUEST_CREATE_ROLE':
        case 'REQUEST_EDIT_ROLE':
            return Object.assign({}, state, {isFetching: true});
        case 'RECEIVE_EDIT_ROLE':
            return Object.assign({}, state, {
                entities: Object.assign(
                    {...state.entities},
                    {
                        [action.role.role_id]: {...action.role},
                    },
                ),
            });
        case 'RECEIVE_ROLES':
            return Object.assign({}, state, {
                isFetching: false,
                entities: {
                    ...state.entities,
                    ...action.roles.reduce((acc, role) => {
                        acc[role.role_id] = {...role};
                        return acc;
                    }, {}),
                },
            });
        case 'RECEIVE_ROLE':
            return Object.assign({}, state, {
                isFetching: false,
                entities: Object.assign({}, state.entities.roles, {
                    [action.role.role_id]: {...action.role},
                }),
            });
        case 'RECEIVE_ROLES_FAILURE':
            return Object.assign({}, state, {isFetching: false});
        case 'SAVE_USER_DETAILS':
            return Object.assign({}, state, {
                currentRoleId: action.user.role_id,
            });
        default:
            return state;
    }
}
