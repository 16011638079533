import {omit} from 'utils/helpers';

const initialState = {
    isFetching: false,
    isSaving: false,
    assignments: {},
    entities: {},
    assignedListings: [],
    filters: {
        has_editorial: true,
    },
    settings: {
        theme: 'mdn-like-light',
        maximized: false,
    },
};

export default function editorials(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_CREATE_EDITORIAL':
            return {
                ...state,
                isCreating: true,
            };
        case 'REQUEST_EDITORIAL':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_EDITORIALS':
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                isCreating: false,
                entities: action.editorials.reduce(
                    (acc, editorial) => {
                        acc[editorial.editorial_id] = {
                            ...acc[editorial.editorial_id],
                            ...editorial,
                        };

                        return acc;
                    },
                    {...state.entities},
                ),
            };
        case 'RECEIVE_PUBLISH_EDITORIAL':
        case 'RECEIVE_SUBMIT_EDITORIAL':
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                isCreating: false,
                entities: {
                    ...state.entities,
                    [action.editorial.editorial_id]: action.editorial,
                },
            };
        case 'RECEIVE_ASSIGN_EDITORIAL':
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                isCreating: false,
                entities: {
                    ...state.entities,
                    [action.editorial.editorial_id]: action.editorial,
                },
            };
        case 'RECEIVE_CREATE_EDITORIAL':
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                isCreating: false,
                entities: {
                    ...state.entities,
                    [action.editorial.editorial_id]: action.editorial,
                },
            };
        case 'REQUEST_EDITORIAL_ASSIGNMENTS':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_EDITORIAL_ASSIGNMENTS':
            return {
                ...state,
                isFetching: false,
                assignments: action.editorials.reduce((acc, editorial) => {
                    acc[editorial.editorial_id] = omit(
                        editorial,
                        'draft_html',
                        'draft_markdown',
                        'published_markdown',
                        'published_html',
                        'editorial',
                    );
                    return acc;
                }, {}),
            };
        case 'RECEIVE_EDITORIAL_ASSIGNMENTS_FAILURE':
            return {
                ...state,
                isFetching: false,
            };
        case 'UPDATE_ASSIGNED_EDITORIALS':
            return {
                ...state,
                assignedListings: action.list,
            };
        case 'CLEAR_ASSIGNED_EDITORIALS':
            return {
                ...state,
                assignedListings: [],
            };
        case 'REQUEST_DELETE_EDITORIAL':
        case 'REQUEST_ASSIGN_EDITORIAL':
        case 'REQUEST_PUBLISH_EDITORIAL':
        case 'REQUEST_SUBMIT_EDITORIAL':
        case 'REQUEST_EDIT_EDITORIAL':
            return {
                ...state,
                isSaving: true,
            };
        case 'RECEIVE_EDITORIAL_FAILURE':
            return {
                ...state,
                isFetching: false,
                isSaving: false,
                isCreating: false,
            };

        case 'RECEIVE_LISTING': {
            const editorial = action.listing.editorial;
            if (!editorial) return state;
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                entities: {
                    ...state.entities,
                    [editorial.editorial_id]: {
                        ...state.entities[editorial.editorial_id],
                        ...editorial,
                    },
                },
            };
        }
        case 'RECEIVE_DELETE_EDITORIAL':
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                entities: omit(state.entities, action.editorialId),
            };
        case 'SAVE_EDITORIAL_CONTENTS':
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.editorialId]: {
                        ...state.entities[action.editorialId],
                        ...action.contents,
                    },
                },
            };
        case 'SAVE_EDITORIAL_SETTINGS':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.setting,
                },
            };
        case 'UPDATE_EDITORIAL_FILTERS':
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value,
                },
            };
        case 'RESET_EDITORIAL_FILTERS':
            return {
                ...state,
                filters: initialState.filters,
            };
        default:
            return state;
    }
}

export function workingSets(
    state = {
        entities: {},
        isFetching: false,
        isSaving: false,
        pagination: null,
        results: [],
        selectedListings: [],
        workspaceResults: [],
        activeSetId: null,
        expandedSetId: null,
    },
    action,
) {
    switch (action.type) {
        case 'UPDATE_EXPANDED_SET_ID':
            return {
                ...state,
                expandedSetId: action.id,
                activeSetId: action.id ? action.id : state.activeSetId,
            };
        case 'REQUEST_WORKING_SETS':
            return {
                ...state,
                isSaving: true,
            };
        /**
         * Replace entity list
         */
        case 'RECEIVE_WORKING_SET_RESULTS':
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                entities: action.sets.reduce((acc, set) => {
                    acc[set.id] = {
                        ...state.entities[set.id],
                        ...set,
                    };

                    return acc;
                }, {}),
            };
        /**
         * Clobber entities
         */
        case 'RECEIVE_WORKING_SETS':
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                entities: {
                    ...state.entities,
                    ...action.sets.reduce((acc, set) => {
                        acc[set.id] = {
                            ...state.entities[set.id],
                            ...set,
                        };

                        return acc;
                    }, {}),
                },
            };
        case 'RECEIVE_WORKING_SET_FAILURE':
            return {
                ...state,
                isFetching: false,
                isSaving: false,
            };
        case 'RECEIVE_DELETE_WORKING_SET':
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                entities: omit(state.entities, action.id),
            };
        case 'UPDATE_ACTIVE_SET_ID':
            return {
                ...state,
                activeSetId: action.id,
            };
        case 'UPDATE_ACTIVE_EDITORIAL_LIST':
            return {
                ...state,
                activeListIndex: action.index,
            };
        case 'REQUEST_EDITORIAL_SEARCH':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_EDITORIAL_SEARCH_RESULTS':
            return {
                ...state,
                isFetching: false,
                pagination: {...action.pagination},
                results: [...action.listings],
            };
        case 'REQUEST_WORKSPACE_LISTINGS':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_WORKSPACE_LISTINGS':
            return {
                ...state,
                isFetching: false,
                workspaceResults: action.listings,
            };
        case 'UPDATE_WORKSPACE_RESULTS': {
            const {editorial} = action;
            return {
                ...state,
                workspaceResults: state.workspaceResults.map((listing) => {
                    if (listing.editorial_id === editorial.editorial_id) {
                        return {
                            ...listing,
                            editorial_latest_attachment: {
                                file_name: action.editorial.attachments[0],
                            },
                        };
                    }

                    return listing;
                }),
            };
        }
        case 'CHECK_LISTINGS_FROM_SEARCH':
            return {
                ...state,
                selectedListings: [
                    ...state.selectedListings.filter(
                        (listing) => !action.ids.includes(listing.id),
                    ),
                    ...state.results
                        .map((listing) => ({
                            name: listing.listing_name,
                            id: listing.listing_id,
                        }))
                        .filter((listing) => listing && action.ids.includes(listing.id)),
                ],
            };
        case 'UNCHECK_LISTINGS_FROM_SEARCH':
            return {
                ...state,
                selectedListings: state.selectedListings.filter(
                    (listing) => !action.ids.includes(listing.id),
                ),
            };
        // case 'REMOVE_SAVED_LISTING':
        //     return {
        //         ...state,
        //         savedLists: [
        //             ...state.savedLists.slice(0, action.index),
        //             {
        //                 ...state.savedLists[action.index],
        //                 listings: state.savedLists[action.index].listings.filter(
        //                     listing => listing.id !== action.id
        //                 )
        //             },
        //             ...state.savedLists.slice(action.index + 1)
        //         ]
        //     };
        // case 'UPDATE_ACTIVE_EDITORIAL_LIST_LISTINGS':
        //     const selectedIds = state.selectedListings.map(listing => listing.id);
        //     const index = state.activeListIndex;
        //     return {
        //         ...state,
        //         selectedListings: [],
        //         expandedIndex: index,
        //         savedLists: [
        //             ...state.savedLists.slice(0, index),
        //             {
        //                 ...state.savedLists[index],
        //                 listings: [
        //                     ...state.savedLists[index].listings.filter(
        //                         listing => !selectedIds.includes(listing.id)
        //                     ),
        //                     ...state.selectedListings
        //                 ]
        //             },
        //             ...state.savedLists.slice(index + 1)
        //         ]
        //     };
        default:
            return state;
    }
}
