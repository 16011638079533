const initialState = {};

export default function twofa(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_TWO_FACTOR_AUTHENTICATION':
            return {
                ...state,
                email: action.data?.email,
            };
        default:
            return state;
    }
}
