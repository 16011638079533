import {omit, getEventId} from 'utils/helpers';

export default function billing(
    state = {
        isFetching: false,
        isDeleting: false,
        isCreatingDispute: false,
        accountId: null,
        cards: {},
        statuses: {},
        transactions: [],
        transactionPagination: {},
        payments: [],
        paymentsPagination: {},
    },
    action,
) {
    switch (action.type) {
        case 'REQUEST_BILLABLE_STATUSES':
        case 'REQUEST_TRANSACTIONS':
            // reset the transactions so that the previous account's transactions don't show up in the current transactions of the current account.
            return Object.assign({}, state, {isFetching: true, transactions: []});
        case 'RECEIVE_BILLABLE_STATUSES_FAILURE':
        case 'RECEIVE_TRANSACTIONS_FAILURE':
            return Object.assign({}, state, {isFetching: false});
        case 'RECEIVE_CARDS_FAILURE':
            return Object.assign({}, state, {isFetching: false});
        case 'RECEIVE_TRANSACTIONS':
            return Object.assign({}, state, {
                isFetching: false,
                transactionPagination: action.pagination ? action.pagination : {},
                accountId: action.accountId,
                transactions: action.transactions,
            });
        case 'REQUEST_CREATE_DISPUTE':
            return Object.assign({}, state, {
                isCreatingDispute: true,
            });
        case 'REQUEST_CARD':
        case 'REQUEST_CARDS':
            return Object.assign({}, state, {isFetching: true});
        case 'SAVE_PRIMARY_CARD_ID':
            return Object.assign({}, state, {primaryCardId: action.id});
        case 'RECEIVE_CARDS':
            return Object.assign({}, state, {
                isFetching: false,
                cards: Object.assign(
                    {},
                    {...state.cards},
                    action.cards.reduce((acc, card) => {
                        acc[card.cc_id] = {...card};
                        return acc;
                    }, {}),
                ),
            });
        case 'REQUEST_DELETE_CARD':
            return Object.assign({}, state, {
                isDeleting: true,
            });
        case 'RECEIVE_DELETE_CARD':
            return Object.assign({}, state, {
                isDeleting: false,
                cards: {...omit(state.cards, action.cardId)},
            });
        case 'RECEIVE_DELETE_CARD_FAILURE':
            return Object.assign({}, state, {
                isDeleting: false,
            });
        case 'RECEIVE_CARD': {
            /**
             * If card is now primary,
             * set other cards with same account id "default" field to false
             */
            const isPrimary = action.card.default;
            return Object.assign({}, state, {
                isFetching: false,
                cards: {
                    ...Object.keys(state.cards).reduce((acc, cur) => {
                        if (state.cards[cur].account_id === action.card.account_id) {
                            acc[cur] = {
                                ...state.cards[cur],
                                default: isPrimary ? false : state.cards[cur].default,
                            };
                            return acc;
                        }

                        acc[cur] = {...state.cards[cur]};

                        return acc;
                    }, {}),
                    [action.card.cc_id]: {...action.card},
                },
            });
        }
        case 'RECEIVE_BILLABLE_STATUSES':
            return Object.assign({}, state, {
                statuses: action.statuses.reduce((acc, cur) => {
                    return {
                        ...acc,
                        [cur.name]: cur.display,
                    };
                }, {}),
            });
        case 'REQUEST_PAYMENT_HISTORY':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_PAYMENT_HISTORY':
            return {
                ...state,
                isFetching: false,
                payments: action.records,
                paymentsPagination: action.pagination,
            };
        case 'RECEIVE_PAYMENT_HISTORY_FAILURE':
            return {
                ...state,
                payments: [],
                paymentsPagination: {
                    page: 1,
                    count: 30,
                    total: 0,
                },
                isFetching: false,
            };
        default:
            return state;
    }
}
