const initialState = {
    subscriptions: [],
};

export default function promoted(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_PROMOTED_SUBSCRIPTIONS':
            return {
                ...state,
                subscriptions: action.subscriptions,
            };
        default:
            return state;
    }
}
