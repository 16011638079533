import {omit} from '../../utils/helpers';

export default function users(
    state = {
        isFetching: false,
        isDeleting: false,
        sortKey: 'last_name',
        currentUserId: null,
        ids: [],
        entities: {},
    },
    action,
) {
    switch (action.type) {
        case 'REQUEST_USER':
        case 'REQUEST_CREATE_USER':
        case 'REQUEST_USERS':
            return Object.assign({}, state, {isFetching: true});
        case 'RECEIVE_USERS_FAILURE':
            return Object.assign({}, state, {isFetching: false});
        case 'RECEIVE_USERS':
            return Object.assign({}, state, {
                isFetching: false,
                ids: [
                    ...action.users
                        .filter((user) => !state.ids.includes(user.user_id))
                        .map((user) => user.user_id)
                        .reverse(),
                    ...state.ids,
                ],
                entities: action.users.reduce(
                    (acc, user) => {
                        acc[user.user_id] = Object.assign(
                            {},
                            acc[user.user_id] || {},
                            user,
                        );
                        return acc;
                    },
                    {...state.entities},
                ),
            });
        case 'RECEIVE_USER':
            return Object.assign({}, state, {
                isFetching: false,
                ids: [
                    action.user.user_id,
                    ...state.ids.filter((id) => id !== action.user.user_id),
                ],
                entities: Object.assign(
                    {},
                    {...state.entities},
                    {
                        [action.user.user_id]: {...action.user},
                    },
                ),
            });
        case 'REQUEST_DELETE_USER':
            return Object.assign({}, state, {
                isDeleting: true,
            });
        case 'RECEIVE_DELETE_USER':
            return Object.assign({}, state, {
                isDeleting: false,
                ids: [...state.ids.filter((id) => id !== action.id)],
                entities: {...omit(state.entities, action.id)},
            });
        case 'SAVE_USER_DETAILS':
            return Object.assign({}, state, {
                currentUserId: action.user.user_id,
                ids: [
                    action.user.user_id,
                    ...state.ids.filter((id) => id !== action.user.user_id),
                ],
                entities: {
                    ...state.entities,
                    [action.user.user_id]: action.user,
                },
            });
        case 'SET_USER_VISIBILITY_FILTER':
            return Object.assign({}, state, {
                sortKey: action.filter,
            });
        default:
            return state;
    }
}
