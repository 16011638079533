import {omit, isEmpty, get} from 'utils/helpers';

const initialState = {
    isFetching: false,
    isDeleting: false,
    isPausing: false,
    currentAccountId: null,
    searchQuery: '',
    searchType: '',
    results: [],
    resultsError: null,
    pagination: {},
    entities: {},
};

export default function accounts(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_ACCOUNT':
        case 'REQUEST_ACCOUNTS':
        case 'REQUEST_CREATE_ACCOUNT':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_ACCOUNT_FAILURE':
        case 'RECEIVE_ACCOUNTS_FAILURE':
            return {
                ...state,
                isFetching: false,
                resultsError: action.error,
            };
        case 'RECEIVE_TRANSACTIONS':
            if (isEmpty(action.transactions) || get(action, 'pagination.page') !== 1) {
                return state;
            }

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.accountId]: {
                        ...state.entities[action.accountId],
                        balance: action.transactions[0].balance_after,
                    },
                },
            };
        case 'UPDATE_ACCOUNT_SEARCH':
            return {
                ...state,
                searchQuery: action.query,
            };
        case 'UPDATE_ACCOUNT_SEARCH_TYPE':
            return {
                ...state,
                searchType: action.searchType,
            };
        case 'RECEIVE_ACCOUNT_RESULTS':
            return {
                ...state,
                isFetching: false,
                resultsError: null,
                pagination: action.pagination,
                results: action.accounts,
            };
        case 'MERGE_ACCOUNT_RESULTS':
            return {
                ...state,
                isFetching: false,
                resultsError: null,
                pagination: action.pagination,
                results: action.accounts,
            };
        case 'RECEIVE_CREATE_ACCOUNT':
        case 'RECEIVE_ACCOUNT':
            return {
                ...state,
                isFetching: false,
                entities: Object.assign(
                    {...state.entities},
                    {
                        [action.account.account_id]: {...action.account},
                    },
                ),
            };
        case 'REQUEST_DELETE_ACCOUNT':
            return {
                ...state,
                isDeleting: true,
            };
        case 'RECEIVE_DELETE_ACCOUNT':
            return Object.assign({}, state, {
                isDeleting: false,
                entities: {...omit(state.entities, action.id)},
            });
        case 'SAVE_USER_DETAILS':
            return Object.assign({}, state, {
                currentAccountId: action.user.account_id,
                currentAccountType: action.user.account_type,
            });

        case 'RECEIVE_CARDS':
            return Object.assign({}, state, {
                entities: Object.assign(
                    {...state.entities},
                    {
                        [action.accountId]: Object.assign(
                            {},
                            state.entities[action.accountId],
                            {
                                cc_ids: [...action.cards.map((card) => card.cc_id)],
                            },
                        ),
                    },
                ),
            });
        case 'RECEIVE_DELETE_LISTING':
            if (!state.entities[action.accountId]) return state;

            return Object.assign({}, state, {
                entities: Object.assign(
                    {...state.entities},
                    {
                        [action.accountId]: {
                            ...state.entities[action.accountId],
                            listings: [
                                state.entities[action.accountId].listings.filter(
                                    (id) => id !== action.listingId,
                                ),
                            ],
                        },
                    },
                ),
            });
        default:
            return state;
    }
}
