import {createSlice} from '@reduxjs/toolkit';
import {get} from 'core/configureRequest';

const initialState = {
    subscriptions: [],
};

const vipFlatSlice = createSlice({
    name: 'vipFlat',
    initialState,
    reducers: {
        receiveVipFlatSubscriptions(state, action) {
            return {subscriptions: action.payload};
        },
    },
});

export const {receiveVipFlatSubscriptions} = vipFlatSlice.actions;

export const getVipFlatListingSubscriptions = (accountId) => (dispatch, getState) => {
    return get(`/proxy/api/vip-flat/subscription/?account_id=${accountId}&is_active=true`)
        .then((data) => {
            dispatch(receiveVipFlatSubscriptions(data.data.subscriptions));
        })
        .catch((e) => {
            return Promise.reject(e);
        });
};

export default vipFlatSlice.reducer;
