const initialState = {
    token: null,
    isAuthenticated: false,
    isLoggingIn: false,
    isResettingPassword: false,
    loginAttempts: 0,
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_LOGIN':
            return Object.assign({}, state, {
                isLoggingIn: true,
            });
        case 'LOGIN_SUCCESS':
            return Object.assign({}, state, {
                loginAttempts: 0,
                isAuthenticated: true,
                isLoggingIn: false,
                ...action.data,
            });
        case 'LOGIN_2FA_INIT':
            return Object.assign({}, state, {
                token: null,
                ...action.data,
            });
        case 'LOGIN_FAILURE':
            return Object.assign({}, state, {
                loginAttempts: state.loginAttempts + 1,
                token: null,
                isLoggingIn: false,
            });
        case 'REQUEST_PASSWORD_RESET':
            return Object.assign({}, state, {
                isResettingPassword: true,
            });
        case 'RECEIVE_PASSWORD_RESET':
            return Object.assign({}, state, {
                isResettingPassword: false,
            });
        default:
            return state;
    }
}
