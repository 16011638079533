// import {createSelector} from 'reselect';

// const getToken = (state) => state && state.auth && state.auth.token;
// export const selectToken = createSelector([getToken], (token) => token);

export const selectToken = (state) => {
    // Check if state and auth exist for safety
    if (!state || !state.auth) return null;
    return state.auth.token;
};
