import {get, omit, isEmpty} from 'utils/helpers';

const initialState = {
    isFetching: false,
    isDeleting: false,
    isUpdating: false,
    activeReviewId: null,
    error: null,
    responseError: null,
    pagination: {},
    results: [],
    filters: {
        page: 1,
        count: 100,
        listing_id: null,
        account_id: null,
        ip_address: null,
        status: ['approved', 'flagged'],
        has_official_response: null,
        official_response_status: [],
        from: null,
        to: null,
        author_email: null,
        format: 'json',
    },
};

export default function reviews(state = initialState, action) {
    switch (action.type) {
        /**
         * Reviews
         */
        case 'REQUEST_SEARCH_REVIEWS':
            return {
                ...state,
                isFetching: true,
                error: null,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        case 'RECEIVE_REVIEW_RESULTS':
            return {
                ...state,
                activeReviewId: action.reviews.length && action.reviews[0].review_id,
                responseError: null,
                isFetching: false,
                pagination: action.pagination,
                results: action.reviews,
            };
        case 'RECEIVE_REVIEW_RESULTS_FAILURE':
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case 'REQUEST_EDIT_REVIEW_STATUS':
        case 'REQUEST_EDIT_REVIEW':
            return {
                ...state,
                isUpdating: true,
            };
        case 'RECEIVE_EDIT_REVIEW_STATUS':
        case 'RECEIVE_EDIT_REVIEW':
            return {
                ...state,
                isUpdating: false,
                results: state.results.map((review) => {
                    if (review.review_id === action.review.review_id) {
                        return action.review;
                    }
                    return review;
                }),
            };
        case 'RECEIVE_EDIT_REVIEW_FAILURE':
            return {
                ...state,
                isUpdating: false,
            };

        case 'REQUEST_DELETE_REVIEW':
            return {
                ...state,
                isDeleting: true,
            };
        case 'RECEIVE_DELETE_REVIEW':
            return {
                ...state,
                isDeleting: false,
                activeReviewId: state.results.find((result) => {
                    return result.review_id !== action.reviewId;
                }).review_id,
                results: state.results.filter((result) => {
                    return action.reviewId !== result.review_id;
                }),
            };
        case 'SET_ACTIVE_REVIEW_ID':
            return {
                ...state,
                activeReviewId: action.reviewId,
            };

        /**
         * Responses
         */
        case 'REQUEST_CREATE_RESPONSE':
            return {
                ...state,
                isUpdating: true,
                isFetching: true,
            };
        case 'RECEIVE_CREATE_RESPONSE':
            return {
                ...state,
                isFetching: false,
                isUpdating: false,
                results: state.results.map((result) => {
                    if (result.review_id === action.response.review_id) {
                        return {
                            ...result,
                            official_response: action.response,
                        };
                    }
                    return result;
                }),
            };
        case 'RECEIVE_CREATE_RESPONSE_FAILURE':
            return {
                ...state,
                isUpdating: false,
                isFetching: false,
                responseError: action.error,
            };
        case 'REQUEST_EDIT_RESPONSE_STATUS':
        case 'REQUEST_EDIT_RESPONSE':
            return {
                ...state,
                responseError: null,
                isUpdating: true,
            };
        case 'RECEIVE_EDIT_RESPONSE_STATUS':
        case 'RECEIVE_EDIT_RESPONSE':
            return {
                ...state,
                isUpdating: false,
                responseError: null,
                results: state.results.map((result) => {
                    if (result.review_id === action.response.review_id) {
                        return {
                            ...result,
                            official_response: action.response,
                        };
                    }
                    return result;
                }),
            };
        case 'RECEIVE_EDIT_RESPONSE_FAILURE':
            return {
                ...state,
                responseError: action.error,
                isUpdating: false,
            };
        case 'REQUEST_DELETE_RESPONSE':
            return {
                ...state,
                responseError: null,
                isDeleting: true,
            };
        case 'RECEIVE_DELETE_RESPONSE':
            return {
                ...state,
                responseError: null,
                isDeleting: false,
            };
        default:
            return state;
    }
}
