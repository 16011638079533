const initialState = {
    screenshotDataUrl: null,
    screenshotLocation: null,
};

export default function issues(state = initialState, action) {
    switch (action.type) {
        case 'SAVE_SCREENSHOT':
            return {
                ...state,
                screenshotDataUrl: action.dataUrl,
                screenshotLocation: action.location,
                screenshotWidth: action.dataUrl ? window.innerWidth : null,
                screenshotHeight: action.dataUrl ? window.innerHeight : null,
            };
        default:
            return state;
    }
}
