import {omit, isEmpty} from '../../utils/helpers';

const initialState = {
    isFetching: false,
    isDeleting: false,
    isPausing: false,
    isSaving: false,
    accountIdsFetched: [],
    activePhotoIndex: null,
    entities: {},
    searchResults: [],
    pagination: null,
};

export default function listings(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_LISTINGS_SEARCH':
            return {
                ...state,
                isFetching: false,
                searchResults: action.listings,
                pagination: action.pagination,
            };
        case 'REQUEST_LISTING':
        case 'REQUEST_LISTINGS':
            return {
                ...state,
                isFetching: true,
            };
        case 'REQUEST_EDIT_LISTING':
            return {
                ...state,
                isSaving: true,
            };
        case 'RECEIVE_LISTING_FAILURE':
        case 'RECEIVE_LISTINGS_FAILURE':
            return {
                ...state,
                isFetching: false,
                isSaving: false,
            };
        case 'RECEIVE_LISTING':
            return {
                ...state,
                isFetching: false,
                isSaving: false,
                searchResults: state.searchResults.map((result) => {
                    if (result.listing_id === action.listing.listing_id) {
                        return action.listing;
                    }
                    return result;
                }),
                entities: Object.assign(
                    {},
                    {...state.entities},
                    {
                        [action.listing.listing_id]: {...action.listing},
                    },
                ),
            };
        case 'RECEIVE_LISTINGS':
            return Object.assign({}, state, {
                isFetching: false,
                entities: {
                    ...state.entities,
                    ...action.listings.reduce((acc, cur) => {
                        acc[cur.listing_id] = {
                            ...state.entities[cur.listing_id],
                            ...cur,
                        };
                        return acc;
                    }, {}),
                },
            });
        case 'REQUEST_ACCOUNT_LISTINGS':
            return Object.assign({}, state, {
                isFetching: true,
            });
        case 'RECEIVE_ACCOUNT_LISTINGS': {
            const fetched = state.accountIdsFetched || [];
            return Object.assign({}, state, {
                isFetching: false,
                accountIdsFetched: fetched.includes(action.accountId)
                    ? state.accountIdsFetched
                    : [...state.accountIdsFetched, action.accountId],
                entities: {
                    ...state.entities,
                    ...action.listings.reduce((acc, cur) => {
                        acc[cur.listing_id] = {
                            ...state.entities[cur.listing_id],
                            ...cur,
                        };
                        return acc;
                    }, {}),
                },
            });
        }
        case 'REQUEST_DELETE_LISTING':
            return Object.assign({}, state, {isDeleting: true});
        case 'RECEIVE_DELETE_LISTING':
            return Object.assign({}, state, {
                isDeleting: false,
                entities: {...omit(state.entities, action.listingId)},
            });
        case 'EDIT_LISTING_PHOTO':
            return Object.assign({}, state, {
                activePhotoIndex: action.index === state.index ? null : action.index,
            });
        default:
            return state;
    }
}
