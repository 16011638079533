const initialState = {
    isFetching: false,
    names: [],
    entities: {},
};

export default function permissions(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_PERMISSIONS':
            return Object.assign({}, state, {isFetching: true});
        case 'RECEIVE_PERMISSIONS':
            return Object.assign({}, state, {
                isFetching: false,
                names: action.permissions.map((permission) => permission.name),
                entities: action.permissions.reduce((acc, cur) => {
                    acc[cur.name] = {...cur};
                    return acc;
                }, {}),
            });
        default:
            return state;
    }
}
