import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    type: null,
    props: {},
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showModal(state, action) {
            return action.payload;
        },
        hideModal(state, action) {
            return initialState;
        },
        toggleModal(state, action) {
            if (state.type) {
                return initialState;
            } else {
                return action.payload;
            }
        },
    },
});

export const {showModal, hideModal, toggleModal} = modalSlice.actions;

export default modalSlice.reducer;
