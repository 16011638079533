import {store} from 'lib/store';
import {logoutUser, promptLogin} from 'features/auth/actions';
import {selectToken} from 'features/auth/selectors';

// @todo - use RTK query, move MakeRequest logic into prepareHeaders
export const makeRequest = (method, endpoint, headers, body, otherOptions) => {
    const token = selectToken(store.getState());

    let requestConfig = {
        method,
        credentials: 'include',
        headers: {
            Authorization: token,
            ...headers,
        },
    };

    if (body) {
        requestConfig.body = body;
    }

    if (otherOptions) {
        requestConfig = {
            ...requestConfig,
            ...otherOptions,
        };
    }

    // console.log('METHOD', method);
    // console.log('ENDPOINT', endpoint);
    // console.log('HEADERS', headers);
    // console.log('BODY', body);

    const timerName = '[Time]-' + Math.random();
    return fetch(endpoint, requestConfig)
        .then((res) => res.json())
        .catch((e) => ({success: false, message: e.message}))
        .then((data) => {
            /** Invalid JWT Token */
            // expired JWT token returns data = {
            //  code:401
            //  message: "Expired JWT Token"
            // }
            if (data && data.code === 401) {
                return store.dispatch(logoutUser());
            }
            if (data && data.error_code === 452) {
                return store.dispatch(logoutUser());
            }

            if (!data || !data.success) {
                console.warn(data);
                return Promise.reject(data);
            }

            return data;
        });
};

const contentType = {
    'Content-type': 'application/json',
};

export const get = (endpoint, headers = {}) => {
    return makeRequest('GET', endpoint, {...contentType, ...headers});
};

export const del = (endpoint) => {
    return makeRequest('DELETE', endpoint, contentType);
};

export const post = (endpoint, data, headers = {}) => {
    return makeRequest(
        'POST',
        endpoint,
        {...contentType, ...headers},
        JSON.stringify(data),
    );
};

export const patch = (endpoint, data) => {
    return makeRequest('PATCH', endpoint, contentType, JSON.stringify(data));
};

export const upload = (endpoint, data) => {
    return makeRequest('POST', endpoint, contentType, JSON.stringify(data), {
        gzip: true,
        timeout: 15000,
    });
};
