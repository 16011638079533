const initialState = {
    isFetchingAccountAnalytics: false,
    accountAnalyticsFetched: [],
    accountAnalytics: {},
    isFetchingListingAnalytics: false,
    listingAnalyticsFetched: [],
    listingAnalytics: {},
    activeEventType: 'clicks',
    // activeDateRange: 'thirtyDays',
    activeStartingDate: null,
    activeEndingDate: null,
};

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_DASHBOARD_EVENT_TYPE':
            return Object.assign({}, state, {
                activeEventType: action.eventType,
            });
        case 'UPDATE_DASHBOARD_DATE_RANGE':
            return Object.assign({}, state, {
                //activeDateRange: action.dateRange
                activeStartingDate: action.startDate || state.activeStartingDate,
                activeEndingDate: action.endDate || state.activeEndingDate,
            });
        case 'REQUEST_LISTING_ANALYTICS':
            return Object.assign({}, state, {isFetchingListingAnalytics: true});
        case 'RECEIVE_LISTING_ANALYTICS_FAILURE':
            return Object.assign({}, state, {
                listingAnalyticsFetched: state.listingAnalyticsFetched.includes(
                    action.accountId,
                )
                    ? state.listingAnalyticsFetched
                    : [...state.listingAnalyticsFetched, action.accountId],
                isFetchingListingAnalytics: false,
            });
        case 'RECEIVE_LISTING_ANALYTICS': {
            const {listingAnalyticsFetched} = state;
            const fetched =
                listingAnalyticsFetched &&
                listingAnalyticsFetched.includes(action.accountId)
                    ? state.listingAnalyticsFetched
                    : [...state.listingAnalyticsFetched, action.accountId];
            return Object.assign({}, state, {
                isFetchingListingAnalytics: false,
                listingAnalyticsFetched: fetched,
                listingAnalytics: {
                    ...state.listingAnalytics,
                    ...action.records.reduce((acc, cur) => {
                        acc[cur.listing_id] = {
                            ...cur,
                            account_id: action.accountId,
                        };

                        return acc;
                    }, {}),
                },
            });
        }
        case 'REQUEST_ACCOUNT_ANALYTICS':
            return Object.assign({}, state, {isFetchingAccountAnalytics: true});
        case 'RECEIVE_ACCOUNT_ANALYTICS_FAILURE':
            return Object.assign({}, state, {
                accountAnalyticsFetched: state.accountAnalyticsFetched.includes(
                    action.accountId,
                )
                    ? state.accountAnalyticsFetched
                    : [...state.accountAnalyticsFetched, action.accountId],
                isFetchingAccountAnalytics: false,
            });
        case 'RECEIVE_ACCOUNT_ANALYTICS':
            return Object.assign({}, state, {
                isFetchingAccountAnalytics: false,
                accountAnalyticsFetched: state.accountAnalyticsFetched.includes(
                    action.accountId,
                )
                    ? state.accountAnalyticsFetched
                    : [...state.accountAnalyticsFetched, action.accountId],
                accountAnalytics: {
                    ...state.accountAnalytics,
                    [action.accountId]: [...action.records],
                },
            });
        case 'KNOWLEDGE_BASE_CLICK':
            return state;
        default:
            return state;
    }
}
