import {omit, isEmpty} from 'utils/helpers';

const initialState = {
    isFetching: false,
    isEditingId: null,
    entities: {},
};

export default function feedback(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_FEEDBACK':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_FEEDBACK':
            return {
                ...state,
                isEditingId: null,
                isFetching: false,
                entities: action.feedback.reduce(
                    (acc, cur) => {
                        acc[cur.feedback_id] = {
                            ...acc[cur.feedback_id],
                            ...cur,
                        };
                        return acc;
                    },
                    {...state.entities},
                ),
            };
        case 'REQUEST_LISTING':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_LISTING': {
            const feedback =
                (action.listing.editorial && action.listing.editorial.feedback_items) ||
                [];
            return {
                ...state,
                isFetching: false,
                entities: feedback.reduce(
                    (acc, cur) => {
                        acc[cur.feedback_id] = {
                            ...acc[cur.feedback_id],
                            ...cur,
                        };
                        return acc;
                    },
                    {...state.entities},
                ),
            };
        }
        case 'REQUEST_EDIT_FEEDBACK':
            return {
                ...state,
                isEditingId: action.feedbackId,
            };
        case 'RECEIVE_LISTING_FAILURE':
        case 'RECEIVE_FEEDBACK_FAILURE':
            return {
                ...state,
                isEditingId: null,
                isFetching: false,
            };
        case 'RECEIVE_DELETE_FEEDBACK':
            return {
                ...state,
                isEditingId: null,
                isFetching: false,
                entities: {
                    ...omit(state.entities, action.feedbackId),
                },
            };
        default:
            return state;
    }
}
