const initialState = {
    downloadPath: null,
    downloadData: null,
    isDownloading: false,
    uploadPath: null,
    isUploading: false,
};

export default function transfer(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_DOWNLOAD':
            return Object.assign({}, state, {
                isDownloading: true,
            });
        case 'DOWNLOAD_FILE':
            return Object.assign({}, state, {
                downloadPath: action.downloadPath,
                downloadData: action.data,
            });
        case 'DOWNLOAD_COMPLETE':
            return Object.assign({}, state, {
                downloadPath: null,
                downloadData: null,
                isDownloading: false,
            });
        case 'REQUEST_UPLOAD':
            return Object.assign({}, state, {
                isUploading: true,
                uploadPath: action.uploadPath,
            });
        case 'RECEIVE_UPLOAD_FAILURE':
            return Object.assign({}, state, {
                isUploading: false,
                uploadPath: null,
            });
        case 'RECEIVE_UPLOAD':
            return Object.assign({}, state, {
                isUploading: false,
                uploadPath: null,
            });
        default:
            return state;
    }
}
