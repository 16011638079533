import {SubmissionError} from 'redux-form';

import {omit} from 'utils/helpers';
import {post, get, patch} from 'core/configureRequest';
import {showModal, hideModal} from 'features/modal/slice';

export const requestLogin = (credentials) => ({
    type: 'REQUEST_LOGIN',
});

export const receiveLogin = (data) => ({
    type: 'LOGIN_SUCCESS',
    data,
});

export const receiveLogin2FA = (data) => ({
    type: 'RECEIVE_TWO_FACTOR_AUTHENTICATION',
    data,
});

export const receiveLogin2FAInit = (data) => ({
    type: 'LOGIN_2FA_INIT',
    data,
});

export const loginFailure = () => ({
    type: 'LOGIN_FAILURE',
});

export const saveUserDetails = (user) => ({
    type: 'SAVE_USER_DETAILS',
    user,
});

export const refreshToken = () => (dispatch, getState) => {
    return get('/proxy/api/refresh-token/')
        .then((data) => {
            dispatch(
                receiveLogin({
                    token: data.data.token,
                }),
            );

            const decoded = JSON.parse(atob(data.data.token.split('.')[1]));

            dispatch(saveUserDetails(decoded.data));

            return data;
        })
        .catch((e) => Promise.reject(e));
};

export const loginUser = (credentials) => (dispatch, getState) => {
    // const token = selectToken(getState());
    // credentials = {...credentials, authorization: `Bearer ${token}`};

    dispatch(requestLogin(credentials));
    return post('/proxy/login_check', credentials)
        .then((data) => {
            dispatch(
                receiveLogin({
                    token: data.data.token,
                }),
            );

            if (!data?.data?.login_auth_code) {
                const decoded = JSON.parse(atob(data.data.token?.split('.')[1]));
                dispatch(saveUserDetails(decoded.data));
            } else {
                dispatch(receiveLogin2FA({email: credentials.username}));
            }

            return data;
        })
        .catch((e) => {
            dispatch(loginFailure());

            const error = e && e.message ? e.message : 'Could not handle request';
            return Promise.reject(new SubmissionError({_error: error}));
        });
};

export const loginUser2FA = (credentials) => (dispatch, getState) => {
    dispatch(requestLogin(credentials));
    return post('/proxy/login_check_2fa', credentials)
        .then((data) => {
            dispatch(
                receiveLogin({
                    token: data.data.token,
                }),
            );

            const decoded = JSON.parse(atob(data?.data?.token?.split('.')[1]));

            dispatch(saveUserDetails(decoded.data));
            if (credentials?.login_auth_code) {
                dispatch(receiveLogin2FA({email: null}));
            }

            return data;
        })
        .catch((e) => {
            dispatch(loginFailure());

            const error = e && e.message ? e.message : 'Could not handle request';
            return Promise.reject(new SubmissionError({_error: error}));
        });
};

export const logoutUser = () => (dispatch) => {
    dispatch({type: 'LOGOUT'});
};

export const setPassword =
    ({password, token}) =>
    (dispatch, getState) => {
        return patch(`/proxy/password_reset/${token}/`, {password});
    };

export const resetPassword = (email) => (dispatch, getState) => {
    return post(`/proxy/password_update_request/${email}/`);
};

export const promptLogin = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            showModal({
                type: 'LOGIN',
                // props: {
                //     successCallback: (user) => resolve(user),
                // },
            }),
        );
    });
};

export const verifyEmail = (token) => (dispatch, getState) => {
    return patch(`/proxy/email_verification/${token}/`);
};
