const initialState = {
    isFetching: false,
    isCreating: false,
    isSaving: false,
    activeId: null,
    packages: {},
    subscriptions: {},
    subscriptionResults: [],
    subscriptionPagination: {},
    invoicePagination: {},
};

export default function vip(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_VIP_INVOICE_RESULTS':
        case 'REQUEST_VIP_PACKAGES':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_VIP_PACKAGES':
            return {
                ...state,
                isFetching: false,
                packages: action.packages.reduce(
                    (acc, pkg) => {
                        acc[pkg.id] = {
                            ...acc[pkg.id],
                            ...pkg,
                        };
                        return acc;
                    },
                    {...state.packages},
                ),
            };
        case 'RECEIVE_VIP_PACKAGES_FAILURE':
            return {
                ...state,
                isFetching: false,
            };
        case 'UPDATE_VIP_PACKAGE_FILTER':
            return {
                ...state,
                packageFilter: action.filter,
            };
        case 'REQUEST_VIP_SUBSCRIPTIONS':
            return {
                ...state,
                isFetching: true,
            };
        case 'RECEIVE_VIP_SUBSCRIPTION_RESULTS':
            return {
                ...state,
                isFetching: false,
                subscriptionResults: action.subscriptions,
                subscriptionPagination: action.pagination,
            };
        case 'RECEIVE_VIP_SUBSCRIPTION_FAILURE':
            return {
                ...state,
                isFetching: false,
            };
        case 'REQUEST_CREATE_VIP_SUBSCRIPTION':
            return {
                ...state,
                isCreating: true,
            };
        case 'RECEIVE_CANCEL_VIP_SUBSCRIPTION':
        case 'RECEIVE_START_VIP_SUBSCRIPTION':
        case 'RECEIVE_CREATE_VIP_SUBSCRIPTION':
        case 'RECEIVE_EDIT_VIP_SUBSCRIPTION':
            return {
                ...state,
                isCreating: false,
                isSaving: false,
                subscriptions: {
                    ...state.subscriptions,
                    [action.subscription.id]: action.subscription,
                },
                subscriptionResults: state.subscriptionResults.map((sub) => {
                    if (sub.id === action.subscription.id) {
                        return action.subscription;
                    }

                    return sub;
                }),
            };
        case 'RECEIVE_VIP_SUBSCRIPTIONS': {
            const ids = action.subscriptions.map((sub) => sub.id);
            return {
                ...state,
                isCreating: false,
                isSaving: false,
                isFetching: false,
                subscriptions: action.subscriptions.reduce(
                    (acc, subscription) => {
                        acc[subscription.id] = {
                            ...acc[subscription.id],
                            ...subscription,
                        };
                        return acc;
                    },
                    {...state.subscriptions},
                ),
                subscriptionResults: state.subscriptionResults.map((sub) => {
                    if (ids.includes(sub.id)) {
                        return action.subscriptions.find(
                            (result) => result.id === sub.id,
                        );
                    }

                    return sub;
                }),
            };
        }
        case 'REQUEST_CANCEL_VIP_SUBSCRIPTION':
        case 'REQUEST_START_VIP_SUBSCRIPTION':
        case 'REQUEST_EDIT_VIP_SUBSCRIPTION':
            return {
                ...state,
                isSaving: true,
            };
        case 'RECEIVE_EDIT_VIP_SUBSCRIPTION_FAILURE':
            return {
                ...state,
                isSaving: false,
            };
        case 'RECEIVE_VIP_INVOICE_RESULTS':
            return {
                ...state,
                isFetching: false,
                invoiceResults: action.invoices,
                invoicePagination: action.pagination,
            };
        default:
            return state;
    }
}
