import {combineSlices, configureStore} from '@reduxjs/toolkit';
import {counterSlice} from './features/counter/counterSlice';
import {quotesApiSlice} from './features/quotes/quotesApiSlice';
import reducers from 'core/reducers';

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

const createNoopStorage = () => {
    return {
        getItem(_key) {
            return Promise.resolve(null);
        },
        setItem(_key, value) {
            return Promise.resolve(value);
        },
        removeItem(_key) {
            return Promise.resolve();
        },
    };
};

const storage =
    typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

// persist state to localStorage
const persistConfig = {
    timeout: 500,
    key: 'root',
    storage,
    // whitelist: [authSlice.name],
};

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices(counterSlice, quotesApiSlice, reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

// `makeStore` encapsulates the store configuration to allow
// creating unique store instances, which is particularly important for
// server-side rendering (SSR) scenarios. In SSR, separate store instances
// are needed for each request to prevent cross-request state pollution.
export let store = null;
export const makeStore = () => {
    store = configureStore({
        reducer: persistedReducer,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                immutableCheck: false,
                // serializableCheck: false,
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }).concat(quotesApiSlice.middleware);
        },
    });
    return store;
};
