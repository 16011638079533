import {reducer} from 'redux-form';

const checkSelectedRolePermissions = (state, permissions) => ({
    ...state,
    values: {
        ...state.values,
        permissions: [...permissions],
    },
});

export default reducer.plugin({
    'create-role': (state, action) => {
        if (!state) return state;
        switch (action.type) {
            case 'CHECK_SELECTED_PERMISSIONS':
                return {
                    ...state,
                    values: {
                        ...state.values,
                        all_permissions: false,
                        permissions: [...action.permissions],
                    },
                };
            case 'CHECK_ALL_PERMISSIONS':
                return {
                    ...state,
                    values: {
                        ...state.values,
                        permissions: [...action.permissions],
                    },
                };
            case 'UNCHECK_ALL_PERMISSIONS':
                return {
                    ...state,
                    values: {
                        ...state.values,
                        role_id: '',
                        permissions: [],
                    },
                };
            default:
                return state;
        }
    },
    'edit-role': (state, action) => {
        if (!state) return state;
        switch (action.type) {
            case 'CHECK_SELECTED_PERMISSIONS':
                return checkSelectedRolePermissions(state, action.permissions);
            default:
                return state;
        }
    },
    'edit-user': (state, action) => {
        if (!state) return state;
        switch (action.type) {
            case 'CHECK_SELECTED_PERMISSIONS':
                return checkSelectedRolePermissions(state, action.permissions);
            case 'RESET_VALUES':
                return {
                    ...state,
                    values: Object.assign({}, state.values, {...action.values}),
                };
            default:
                return state;
        }
    },
    'create-user': (state, action) => {
        if (!state) return state;
        switch (action.type) {
            case 'CHECK_SELECTED_PERMISSIONS':
                return checkSelectedRolePermissions(state, action.permissions);
            case 'UNCHECK_ALL_PERMISSIONS':
                return {
                    ...state,
                    values: {
                        ...state.values,
                        role_id: '',
                        permissions: [],
                    },
                };
            default:
                return state;
        }
    },
    'edit-listing-details': (state, action) => {
        if (!state) return state;
        switch (action.type) {
            case 'UPDATE_LISTING_DAYS': {
                const pricingObj = state.values.pricings[action.index];

                let updatedDays = parseInt(action.value);

                if (pricingObj.display_as === 'month') {
                    updatedDays = updatedDays * 30;
                }

                if (pricingObj.display_as === 'week') {
                    updatedDays = updatedDays * 7;
                }

                return {
                    ...state,
                    values: {
                        ...state.values,
                        pricings: [
                            ...state.values.pricings.slice(0, action.index),
                            {
                                ...pricingObj,
                                days: updatedDays,
                                display_as: pricingObj.display_as || 'day',
                            },
                            ...state.values.pricings.slice(action.index + 1),
                        ],
                    },
                };
            }
            default:
                return state;
        }
    },
});
